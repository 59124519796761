import { AxiosResponse } from 'axios';
import IDocument from 'model/document';
import {
  IBankAccountData,
  IInvoice,
  INewTransferToAccountRequest,
  IReturnMessage,
  ITranseferHistorybyRegister,
  Organizations,
  Providers,
  SuportCfgs,
  TranseferHistory,
  TransferRequest,
  TransferToAccountRequest,
} from 'model/financial';
import { encryptReport, sendLog } from 'shared/util/api-utils';
import { apiCore, apiExtranetFiles } from './api';

export const FinancialApi = () => {
  const getOrganizations = (): Promise<AxiosResponse<Organizations[]>> => {
    sendLog('Consulta organizações financeiras', `/Organiz/GetOrganizacoes`, JSON.stringify({}), 'get');
    return apiCore.get<Organizations[]>(`/Organiz/GetOrganizacoes`);
  };

  const getProviders = (): Promise<AxiosResponse<Providers[]>> => {
    sendLog('Consulta fornecedores', `/Financ/GetFornecedores`, JSON.stringify({}), 'get');
    return apiCore.get<Providers[]>(`/Financ/GetFornecedores`);
  };

  const getTaxCalendarYear = (): Promise<AxiosResponse<SuportCfgs[], any[]>> => {
    sendLog('Consulta imposto de renda', `Suporte/GetSuporteCfgs`, JSON.stringify({}), 'get');
    return apiCore.get<SuportCfgs[]>(`Suporte/GetSuporteCfgs`);
  };

  const getTitleAgoFrisia = (): Promise<AxiosResponse<SuportCfgs[], any[]>> => {
    return apiCore.get<SuportCfgs[]>(`Suporte/GetSuporteCfgs`);
  };

  const getMonthlyMovimentReportbyProvider = (initDay: string, endDay: string, register: string, providerKey: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/TI/EXT_NF_FORNEC_COOP&p_nr_matricula=${register}&p_nr_keyfornecedor=${providerKey}&p_dt_inicio=${initDay}&p_dt_fim=${endDay}`;

    sendLog('Relatorio de movimento mensal por fornecedor', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };

  const getMonthlyMovimentReport = (
    initDay: string,
    endDay: string,
    register: string,
    organizationName: string,
    plate: string,
    prodType: string
  ) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/EBS/OM/XXFROM1053RB&p_id_unidade_operacional=81&p_dt_periodo_inicio=${initDay}&p_dt_periodo_fim=${endDay}&p_nr_conta_cliente_inicio=${register}&p_nr_conta_cliente_fim=${register}&p_nm_organizacao=${organizationName}&p_nr_placa=${plate}&p_cd_tipo_produto=${prodType}`;
    sendLog('Relatorio de movimento mensal', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };

  const getTaxReport = (year: string, register: string) => {
    const path = `https://reports.frisia.coop.br/ReportServer?/EBS/COOP/XXFRCOOP0100R&p_org_id=81&p_nr_ano_calendario=${year}&p_cd_pessoa=${register}`;

    sendLog('Relatorio de inmpostos', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };

  const getFinancialPositionReport = (params: string, register: string, reportName: string, date: string = '', finalDate: string = '') => {
    let path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR1000R&p_cd_pessoa=${register}&p_dt_base=${date}`;

    switch (reportName) {
      case 'XXFRAR0100R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR1000R&p_cd_pessoa=${register}&p_dt_base=${date}${params}`;
        break;

      case 'XXFRAR0101R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0101R&p_cd_pessoa=${register}&p_dt_base=${date}&p_dt_base_final=${finalDate}${params}`;
        break;

      case 'XXFRAR0102R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0102R&p_cd_pessoa=${register}${params}`;
        break;

      case 'XXFRAR0103R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0103R&p_cd_pessoa=${register}${params}`;
        break;

      case 'XXFRAR0104R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0104R&p_cd_pessoa=${register}&p_dt_base=${date}&p_dt_base_final=${finalDate}${params}`;
        break;

      case 'XXFRAR0105R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0105R&p_cd_pessoa=${register}${params}`;
        break;

      case 'XXFRAR0106R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0106R&p_cd_pessoa=${register}${params}`;
        break;

      case 'XXFRAR0107R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0107R&p_cd_pessoa=${register}${params}`;
        break;

      case 'XXFRAR0108R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0108R&p_cd_pessoa=${register}&p_dt_base=${date}${params}`;
        break;

      case 'XXFRAR0109R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0109R&p_cd_pessoa=${register}${params}`;
        break;

      case 'XXFRAR0110R':
        path = `https://reports.frisia.coop.br/ReportServer?/EBS/AR/XXFRAR0110R&p_cd_pessoa=${register}${params}`;
        break;
    }
    sendLog('Relatorio de posição financeira', path, JSON.stringify({}), 'get');
    return encryptReport(path);
  };

  const getTransferHistory = (register: string): Promise<AxiosResponse<TranseferHistory[]>> => {
    sendLog('Histórico de transferência entre contas', `/Financeiro/GetallSaques?cd_pessoa=${register}`, JSON.stringify({}), 'get');
    return apiCore.get<TranseferHistory[]>(`/Financeiro/GetallSaques?cd_pessoa=${register}`);
  };

  const getTransferHistoryBetweenRegisters = (register: string): Promise<AxiosResponse<ITranseferHistorybyRegister[]>> => {
    sendLog(
      'Historico de transferência entre cooperados',
      `/Financeiro/GetallTransferenciasEntreCooperados?cd_pessoa=${register}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<ITranseferHistorybyRegister[]>(`/Financeiro/GetallTransferenciasEntreCooperados?cd_pessoa=${register}`);
  };

  const getCapitalAccountPDF = (year: string, register: string) => {
    let register6Digits = register;
    for (let i = register.length; i < 6; i++) {
      register6Digits = '0' + register6Digits;
    }
    sendLog(
      'Consultar pdf Conta capital',
      `/restrictbase64file?pasta=AGO&subpasta=${year}&filename=AGO${year}_${register6Digits}.pdf`,
      JSON.stringify({}),
      'get'
    );
    return apiExtranetFiles.get<IDocument>(`/restrictbase64file?pasta=AGO&subpasta=${year}&filename=AGO${year}_${register6Digits}.pdf`);
  };

  const makeTransfer = (transactionData: TransferRequest) => {
    sendLog(
      'Enviar transferência entre cooperados',
      `/Financeiro/Transferencias/EntreCooperados/CriarTransferenciaCoop`,
      JSON.stringify(transactionData),
      'post'
    );
    return apiCore.post<IReturnMessage>(`/Financeiro/Transferencias/EntreCooperados/CriarTransferenciaCoop`, transactionData);
  };

  const getSeedBonusReport = (year: string, register: string) => {
    sendLog(
      'Pdf bonificação de sementes',
      `/restrictbase64file?pasta=BONIFICACAOSEMENTES&subpasta=${year}&filename=CARTA${register}.pdf`,
      JSON.stringify({}),
      'get'
    );
    return apiExtranetFiles.get<IDocument>(`/restrictbase64file?pasta=BONIFICACAOSEMENTES&subpasta=${year}&filename=CARTA${register}.pdf`);
  };

  const getSobraTecnicaReport = (year: string, register: string) => {
    return apiExtranetFiles.get<IDocument>(`/restrictbase64file?pasta=SOBRATECNICA&subpasta=${year}&filename=CARTA${register}.pdf`);
  };

  const getMilkAdvancedPayment = (year: string, register: string) => {
    return apiExtranetFiles.get<IDocument>(
      `/restrictbase64file?pasta=LEITE&subpasta=ADIANTAMENTO_${year}&filename=ADIANTAMENTO${register}.pdf`
    );
  };

  const getBankAccounts = (register: string) => {
    sendLog('Consulta de contas de bancos', `/Extranet/GetAllContasBancarias?matricula=${register}`, JSON.stringify({}), 'get');
    return apiCore.get<IBankAccountData[]>(`/Extranet/GetAllContasBancarias?matricula=${register}`);
  };

  const transferToAccount = (trasnferInfo: INewTransferToAccountRequest) => {
    sendLog(
      'Transferência entre contas',
      `/Financeiro/Transferencias/EntreContas/CriarSaque?IE_APP=Y`,
      JSON.stringify(trasnferInfo),
      'post'
    );
    return apiCore.post<IReturnMessage>(`/Financeiro/Transferencias/EntreContas/CriarSaque?IE_APP=Y`, trasnferInfo);
  };

  const cancelAccountTransfer = (register: string, trasnferNumber: string) => {
    sendLog(
      'Cancelamento de transferência',
      `/Financeiro/Transferencias/EntreContas/Cancelar/?nr_matricula=${register}&id=${trasnferNumber}`,
      JSON.stringify({}),
      'post'
    );
    return apiCore.post<string>(`/Financeiro/Transferencias/EntreContas/Cancelar/?nr_matricula=${register}&id=${trasnferNumber}`);
  };

  const getInvoices = (initDay: string, endDay: string, register: string): Promise<AxiosResponse<IInvoice[]>> => {
    sendLog(
      'Consulta de notas fiscais',
      `/Financeiro/GetNFSPeriodo?CD_PESSOA=${register}&DT_INICIO=${initDay}&DT_FIM=${endDay}`,
      JSON.stringify({}),
      'get'
    );
    return apiCore.get<IInvoice[]>(`/Financeiro/GetNFSPeriodo?CD_PESSOA=${register}&DT_INICIO=${initDay}&DT_FIM=${endDay}`);
  };

  const cancelTransferRegister = (register: string, trasnferNumber: string) => {
    sendLog(
      'Cancelamento de transferência entre cooperados',
      `/Financeiro/Transferencias/EntreCooperados/Cancelar/?nr_matricula=${register}&id=${trasnferNumber}`,
      JSON.stringify({}),
      'post'
    );
    return apiCore.post<string>(`/Financeiro/Transferencias/EntreCooperados/Cancelar/?nr_matricula=${register}&id=${trasnferNumber}`);
  };

  return {
    getOrganizations,
    getProviders,
    getMonthlyMovimentReport,
    getMonthlyMovimentReportbyProvider,
    getTaxCalendarYear,
    getTitleAgoFrisia,
    getTaxReport,
    getFinancialPositionReport,
    getTransferHistory,
    getCapitalAccountPDF,
    makeTransfer,
    getSeedBonusReport,
    getSobraTecnicaReport,
    getMilkAdvancedPayment,
    getBankAccounts,
    transferToAccount,
    cancelAccountTransfer,
    getTransferHistoryBetweenRegisters,
    getInvoices,
    cancelTransferRegister,
  };
};

export default FinancialApi();
