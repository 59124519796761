import MainPageTitle from 'components/MainPageTitle';
import { StyledButton } from 'components/styled-button/StyledButton';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from 'components/CurrencyInput';
import GenericInputTextAreaToTable from 'components/GenericInputTextAreaToTable';
import InputDateWithSetting from 'components/InputDateWithSetting';
import moment from 'moment';
import RadioButton from 'components/RadioButton';
import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import FinancialService from 'services/financial-service';
import { ReactComponent as LeftIconSvg } from '../../images/frisia/icons/arrow-left.svg';
import { ReactComponent as RightIconSvg } from '../../images/frisia/icons/arrow-right.svg';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import styled from 'styled-components';
import { IBankAccountData, TranseferHistory } from 'model/financial';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import StyledErrorMessage from 'styled-components/StyledErrorMessage';
import { WarningCard } from 'features/agricultural/CostOptions';
import { Trans, withTranslation, useTranslation } from 'react-i18next';
import { ReactComponent as ExclamationIcon } from '../../images/frisia/icons/gray-Icon-exclamation-circle.svg';
import { useHistory } from 'react-router-dom';

interface IForm {
  chosenAccount: string;
  transferValue: string;
  observation: string;
  register: string;
}

interface historyListType {
  [key: string]: string | number | Element | JSX.Element;
}

const TransferBetweenAccounts = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>();
  const [openDeletTransferModal, setOpenDeletTransferModal] = useState<boolean>(false);
  const [openTransferModal, setOpenTransferModal] = useState<boolean>(false);
  const [isLoadingBanksAccounts, setIsLoadingBanksAccounts] = useState<boolean>(true);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [date, setDate] = useState<Date | null>(tomorrow);
  const [schedule, setSchedule] = useState<boolean>(false);
  const [historyList, setHistoryList] = useState<historyListType[]>([]);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [paginatedHistoryList, setPaginatedHistoryList] = useState<historyListType[]>([]);
  const [banksAccounts, setBanksAccounts] = useState<IBankAccountData[]>([]);
  const [chosenAccount, setChosenAccount] = useState<IBankAccountData>();
  const [chosenForCancel, setChosenForCancel] = useState<TranseferHistory>();
  const [APIResponse, setAPIResponse] = useState<string>();
  const [APIResponseError, setAPIResponseError] = useState<string>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isResetInputValue, setIsResetInputValue] = useState<boolean>(false);
  const history = useHistory();

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const getBankAccounts = async () => {
    const result = await FinancialService.getBankAccounts(getUserRegistration.toString());
    setBanksAccounts(result);
    setIsLoadingBanksAccounts(false);
  };

  const schema = yup.object({
    chosenAccount: yup.string().test('test-chosenAccount', t('global.errorMessage.required'), () => chosenAccount != null),
    transferValue: yup
      .string()
      .default(value)
      .test('test-value', t('global.errorMessage.required'), () => {
        if (value != null) {
          return (
            parseFloat(
              value
                .replaceAll('.', '')
                .replaceAll(',', '.')
                .replace(/[^0-9.-]+/g, '')
            ) > 0
          );
        } else {
          return false;
        }
      }),
    observation: yup.string(),
    register: yup.string().default(getUserRegistration.toString()),
  });

  const methods = useForm<IForm>({
    resolver: yupResolver(schema),
  });

  const handleSubmit = (formData: IForm) => {
    if (chosenAccount && openTransferModal) {
      setDisableButton(true);
      FinancialService.transferToAccount({
        matricula: getUserRegistration,
        valor: formData.transferValue
          .replaceAll('.', '')
          .replaceAll(',', '.')
          .replace(/[^0-9.-]+/g, ''),
        deposito: schedule && date !== null ? moment(date).format('YYYY-MM-DD HH:mm:ss') : moment().format('YYYY-MM-DD HH:mm:ss'),
        conta: {
          banco: {
            id: parseFloat(chosenAccount.CD_BANCO),
            nome: chosenAccount.NM_BANCO,
          },
          agencia: {
            numero: chosenAccount.CD_AGENCIA,
          },
          numero: chosenAccount.NR_CONTA_BANCARIA,
          tipo: 'C/C',
        },
        documento: '',
        finalidade: formData.observation,
      })
        .then(response => {
          setAPIResponse(response.Message);
          setAPIResponseError(undefined);
        })
        .catch(response => {
          setAPIResponse(response.data.ExceptionMessage);
          setAPIResponseError(response.data.ExceptionMessage);
        })
        .finally(() => {
          setDisableButton(false);
        });
    } else {
      setOpenTransferModal(true);
    }
  };

  useEffect(() => {
    if (date != null) {
      let mutableDate = new Date(date);
      let today = new Date();
      if (mutableDate.getTime() < today.getTime()) {
        setDate(tomorrow);
      }
    }
  }, [date]);

  const tableHeaderMachinesAndEquipment = [
    t('financial.Requested'),
    t('financial.Ident.'),
    t('financial.Bank'),
    t('financial.Ag.'),
    t('financial.Account'),
    t('financial.Purpose'),
    t('financial.depositDate'),
    t('financial.Amount'),
    t('financial.Situation'),
    t('financial.Origin'),
    t('financial.Protocol'),
  ];

  const cancelTransfer = async () => {
    if (chosenForCancel) {
      const result = await FinancialService.cancelAccountTransfer(getUserRegistration.toString(), chosenForCancel?.Id.toString());
      if (result) {
        setOpenDeletTransferModal(false);
        getHistory();
      }
    }
  };

  const getHistory = async () => {
    const result = await FinancialService.getTransferHistory(getUserRegistration.toString());
    setHistoryList(
      result.map(item => {
        return {
          [t('financial.Requested')]: moment(item.Data, 'DD-MM-YYYY 17:01').format('DD/MM/YY'),
          [t('financial.Ident.')]: item.Id,
          [t('financial.Bank')]: item.Conta.Banco.Nome,
          [t('financial.Ag.')]: `${item.Conta.Agencia.Numero}`,
          [t('financial.Account')]: `${item.Conta.Numero}`,
          [t('financial.Purpose')]: item.Finalidade,
          [t('financial.depositDate')]: moment(item.Deposito, 'DD-MM-YYYY 17:01').format('DD/MM/YY'),
          [t('financial.Amount')]: item.Valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
          [t('financial.Situation')]: item.Status?.includes('análise') ? (
            <div style={{ margin: '6px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              {t('financial.underAnalysis')}
              <StyledButton
                style={{ padding: '8px', lineHeight: '11px' }}
                color="orange"
                type="button"
                onClick={() => {
                  setChosenForCancel(item);
                  setOpenDeletTransferModal(true);
                }}
              >
                <span>{t('global.button.cancel')}</span>
              </StyledButton>
            </div>
          ) : (
            item.Status
          ),
          [t('financial.Origin')]: item.Origem,
          [t('financial.Protocol')]: item.Protocolo,
        };
      })
    );
  };

  const backMenuFinancial = () => {
    history.push('/financeiro');
  };

  useEffect(() => {
    if (showHistory) {
      getHistory();
    }
  }, [showHistory]);

  const paginate = array => {
    setPaginatedHistoryList(array.slice((pageNumber - 1) * 10, pageNumber * 10));
  };

  useEffect(() => {
    paginate(historyList);
    setTotalPages(Math.ceil(historyList.length / 10));
  }, [pageNumber, historyList]);

  useEffect(() => {
    getBankAccounts();
  }, []);

  return (
    <PageContainer>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('card.transferBetweenAccounts')} />
      </StyledPageTitleContainer>
      <StyledButton
        onClick={() => setShowHistory(!showHistory)}
        style={{ height: '42px', position: 'relative', marginBottom: '10px' }}
        color="orange"
        type="button"
      >
        <span>{t('agricultural.history')}</span>
      </StyledButton>
      {paginatedHistoryList.length > 0 && showHistory && (
        <>
          <GenericTableBlueHeader
            headerItems={tableHeaderMachinesAndEquipment}
            tableItems={paginatedHistoryList}
            dataColor="#787878"
            isInvertLineColor
            isDontHaveMaximumWidth
            style={{ fontSize: '12px !important' }}
          />
          <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center' }}>
            <LeftIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (pageNumber > 1) {
                  setPageNumber(pageNumber - 1);
                }
              }}
            />
            <div>
              {pageNumber}/{totalPages}
            </div>
            <RightIconSvg
              style={{ cursor: 'pointer', margin: '8px' }}
              onClick={() => {
                if (pageNumber < historyList.length / 10) {
                  setPageNumber(pageNumber + 1);
                }
              }}
            />
          </div>
        </>
      )}
      {openDeletTransferModal && (
        <ModalContainer>
          <ModalShadow onClick={() => setOpenDeletTransferModal(false)} />
          <CardContainer>
            <SubtitleStyled>{`${t('trr.cancelTransfer')} ${chosenForCancel?.Id}?`}</SubtitleStyled>
            <SubtitleStyled>{t('financial.valueOf')}:</SubtitleStyled>
            R$ {chosenForCancel?.Valor}
            <SubtitleStyled>{t('financial.forAccount')}:</SubtitleStyled>
            {chosenForCancel &&
              `${chosenForCancel?.Conta.Banco.Nome} - ${chosenForCancel?.Conta.Agencia.Numero} - ${chosenForCancel?.Conta.Agencia.Digito} ${chosenForCancel?.Conta.Numero} - ${chosenForCancel?.Conta.Digito}`}
            <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
              <CancelButton
                onClick={() => {
                  setOpenDeletTransferModal(false);
                }}
                style={{ width: '145px', height: '40px' }}
              >
                {t('global.field.no')}
              </CancelButton>
              <StyledButton
                color="frisiaPrimary"
                onClick={() => {
                  cancelTransfer();
                }}
                type="button"
                style={{ color: 'white', minWidth: '145px', height: '42px' }}
              >
                <span>{t('global.field.yes')}</span>
              </StyledButton>
            </div>
          </CardContainer>
        </ModalContainer>
      )}

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          {openTransferModal && (
            <ModalContainer>
              <ModalShadow onClick={() => setOpenTransferModal(false)} />
              <CardContainer>
                {APIResponse ? (
                  <div style={{ margin: '20px' }}>
                    <div> {APIResponse}</div>
                    {!APIResponseError ? (
                      <>
                        <div>{t('financial.createNewTransfer')}</div>
                        <StyledButton
                          color="frisiaPrimary"
                          onClick={() => {
                            methods.reset();
                            methods.reset({ observation: '' });
                            setValue(undefined);
                            setChosenAccount(undefined);
                            setSchedule(false);
                            setAPIResponse(undefined);
                            setOpenTransferModal(false);
                            setIsResetInputValue(true);
                          }}
                          type="button"
                          style={{ color: 'white', minWidth: '145px', height: '42px', marginTop: '20px' }}
                        >
                          <span>{t('global.button.yes')}</span>
                        </StyledButton>
                        <StyledButton
                          color="frisiaSecondary"
                          onClick={() => {
                            setAPIResponse(undefined);
                            setOpenTransferModal(false);
                            backMenuFinancial();
                          }}
                          type="button"
                          style={{ color: 'white', minWidth: '145px', height: '42px', marginTop: '20px' }}
                        >
                          <span>{t('global.button.no')}</span>
                        </StyledButton>
                      </>
                    ) : (
                      <>
                        <StyledButton
                          color="frisiaPrimary"
                          onClick={() => {
                            setAPIResponse(undefined);
                            setOpenTransferModal(false);
                          }}
                          type="button"
                          style={{ color: 'white', minWidth: '145px', height: '42px', marginTop: '20px' }}
                        >
                          <span>{t('global.button.ok')}</span>
                        </StyledButton>
                      </>
                    )}
                  </div>
                ) : (
                  <div style={{ padding: '10px' }}>
                    <SubtitleStyled>{t('financial.valueOf')}:</SubtitleStyled>
                    {value}
                    <SubtitleStyled>{t('financial.forAccount')}:</SubtitleStyled>
                    {chosenAccount &&
                      `${chosenAccount.CD_BANCO} - ${chosenAccount.NM_BANCO}, ${chosenAccount.CD_AGENCIA}, ${chosenAccount.NR_CONTA_BANCARIA}`}
                    <SubtitleStyled>{t('financial.in')}:</SubtitleStyled>
                    {schedule && date !== null ? moment(date).format('DD/MM/YY') : moment().format('DD/MM/YY')}
                    <SubtitleStyled>{t('financial.confirmSolicitation')}</SubtitleStyled>
                    <div style={{ marginTop: '10px', width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                      <CancelButton
                        onClick={() => {
                          setOpenTransferModal(false);
                        }}
                        style={{ width: '145px', height: '40px' }}
                      >
                        {t('financial.cancel')}
                      </CancelButton>
                      <StyledButton
                        color="frisiaPrimary"
                        onClick={() => {
                          setOpenTransferModal(true);
                        }}
                        type="submit"
                        disabled={disableButton}
                        style={{ color: 'white', minWidth: '145px', height: '42px' }}
                      >
                        <span>{t('financial.confirm')}</span>
                      </StyledButton>
                    </div>
                  </div>
                )}
              </CardContainer>
            </ModalContainer>
          )}
          <div>
            <div style={{ marginTop: '10px', display: 'flex', flexDirection: 'column' }}>
              <SubtitleStyled>{t('financial.destinyAccount')}:</SubtitleStyled>
              <StyledContainer style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <LoadingContainerScreen isLoading={isLoadingBanksAccounts}>
                  {banksAccounts.map((item, index) => (
                    <RadioButton
                      key={index}
                      name="account"
                      value={`${item.CD_BANCO} - ${item.NM_BANCO}, ${item.CD_AGENCIA}, ${item.NR_CONTA_BANCARIA}`}
                      id={`account-${index}`}
                      handleClick={() => setChosenAccount(item)}
                      style={{ display: 'inline-flex', width: '100%', margin: '15px 0px', maxWidth: 'none' }}
                    />
                  ))}
                </LoadingContainerScreen>
                <StyledErrorMessage style={{ position: 'initial' }}>{methods.formState.errors.chosenAccount?.message}</StyledErrorMessage>
              </StyledContainer>
            </div>
            <div>
              <SubtitleStyled>{t('financial.newTransfer')}</SubtitleStyled>
              <MyStyledInput>
                <CurrencyInput placeholder="0,00" onChange={e => setValue(e.target.value)} />
              </MyStyledInput>
              <StyledErrorMessage style={{ position: 'initial' }}>{methods.formState.errors.transferValue?.message}</StyledErrorMessage>
            </div>
            <div style={{ margin: '15px 0px' }}>
              <SubtitleStyled>{t('financial.transferDate')}</SubtitleStyled>
              <StyledContainer>
                <RadioButton
                  isDefaultChecked={!schedule}
                  name="orderType"
                  value={String(t('financial.transferToday'))}
                  id="bulk"
                  handleClick={() => setSchedule(false)}
                  style={{ display: 'inline-flex', marginRight: '40px', marginBottom: '0' }}
                />
                <RadioButton
                  name="orderType"
                  value={String(t('financial.schedule'))}
                  id="bagged"
                  handleClick={() => setSchedule(true)}
                  style={{ display: 'inline-flex', marginBottom: '0' }}
                />
              </StyledContainer>
              {schedule && <InputDateWithSetting date={date} setDate={setDate} disableWeekends label={t('dashboard.filterDate')} />}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '200px',
                justifyContent: 'space-around',
              }}
            >
              <SubtitleStyled>{t('financial.finalityDestination')}</SubtitleStyled>
              <GenericInputTextAreaToTable
                inputName="observation"
                label={''}
                placeholder={t('livestock.addObservation')}
                isResetValue={isResetInputValue}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <span>{t('financial.subtitle1')}</span>
                <span>{t('financial.subtitle2')}</span>
                <span>{t('financial.subtitle3')}</span>
                <span>{t('financial.subtitle4')}</span>
              </div>
              <WarningCard>
                <ExclamationIcon />
                <p>
                  <Trans i18nKey="financial.warningMessage">
                    <span className="text-blue">{{ value: t('financial.phoneNumber') }}</span>
                  </Trans>
                </p>
              </WarningCard>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <StyledButton
                color="frisiaPrimary"
                style={{ color: 'white', minWidth: '270px', alignSelf: 'center', margin: '10px', height: '53px' }}
                type="submit"
              >
                <span>{t('financial.transferConclude')}</span>
              </StyledButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </PageContainer>
  );
};

const StyledRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  min-height: 65px;
  margin: 30px 0px;
  margin-bottom: 42px;
  justify-content: space-between;
  div {
    width: fit-content;
    font-weight: bold;
    color: #404040;
  }
  div > span {
    color: #2a5299;
    font-weight: initial;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 150px;
  min-width: 445px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 21px;
  box-shadow: 0px 3px 6px #00000029;
  padding: 15px;
  b {
    align-self: self-start;
    margin: 20px 0px;
  }
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalShadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
`;

export const ModalItems = styled.div`
  background: #fcfcfc 0% 0% no-repeat padding-box;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 278px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
`;

const SubtitleStyled = styled.div`
  text-align: left;
  margin-top: 8px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #2a5299;
  opacity: 1;
  font-family: ${({ theme }) => theme.font.montserratBold};
`;

const MyStyledInput = styled.div`
  input {
    text-align: left;
    font-size: 48px;
    letter-spacing: 0px;
    color: #2a5299;
    opacity: 1;
    text-align: center;
    border: 1px #8080806b solid;
    border-radius: 10px;
  }
`;

const CancelButton = styled.button`
  width: 245px;
  height: 34px;
  font-size: 14px;
  letter-spacing: 0.02px;
  color: #d4a518;
  text-transform: uppercase;
  opacity: 1;
  border: #d4a518 2px solid;
  text-align: center;
  padding: 4px;
  padding-top: 6px;
  cursor: pointer;
`;

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export default withTranslation()(TransferBetweenAccounts);
