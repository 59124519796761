enum CardNames {
  ACCOUNT_MOVEMENT = 'ACCOUNT_MOVEMENT',
  ACCOUNT_BALANCE = 'ACCOUNT_BALANCE',
  CAPITAL_ACCOUNT = 'CAPITAL_ACCOUNT',
  PRODUCTION_ACCOUNT = 'PRODUCTION_ACCOUNT',
  TRANSFER_BETWEEN_ACCOUNTS = 'TRANSFER_BETWEEN_ACCOUNTS',
  TRANSFER_BETWEEN_ENROLLMENTS = 'TRANSFER_BETWEEN_ENROLLMENTS',
  FINANCIAL_POSITION = 'FINANCIAL_POSITION',
  MONTHLY_TRANSACTION = 'MONTHLY_TRANSACTION',
  INCOME_TAX = 'INCOME_TAX',
  MONTHLY_TRANSACTION_INVOICE = 'MONTHLY_TRANSACTION_INVOICE',
  MONTHLY_TRANSACTION_BY_PROVIDER = 'MONTHLY_TRANSACTION_BY_PROVIDER',
  COMPLEMENTARY_SEED_BONUS = 'COMPLEMENTARY_SEED_BONUS',
  SOBRA_TECNICA = 'SOBRA_TECNICA',

  REGISTER = 'REGISTER',
  FRISIA_CANAL = 'FRISIA_CANAL',
  FORESTRY = 'FORESTRY',
  ENVIRONMENTAL = 'ENVIRONMENTAL',
  CLASSIFIED = 'CLASSIFIED',
  COOPERATIVE_ROOM = 'COOPERATIVE_ROOM',

  AGRICULTURAL_QUOTES = 'AGRICULTURAL_QUOTES',
  PRODUCTION_DISCHARGE = 'PRODUCTION_DISCHARGE',

  PLANTEL = 'PLANTEL',

  STOCK = 'STOCK',
  LOAD_LOAD = 'LOAD_LOAD',
  INPUTS = 'INPUTS',
  //COSTING_OPTIONS = 'COSTING_OPTIONS',
  SALES_STATEMENT = 'SALES_STATEMENT',
  SALES_AUTHORIZATION = 'SALES_AUTHORIZATION',
  CONSULT_SALES_AUTHORIZATION = 'CONSULT_SALES_AUTHORIZATION',
  PRODUCT_WITHDRAW_AUTHORIZATION = 'PRODUCT_WITHDRAW_AUTHORIZATION',
  PROFITABILITY = 'PROFITABILITY',
  PACKAGE = 'PACKAGE',
  TRR_REQUESTS_AGRICULTURAL = 'TRR_REQUESTS_AGRICULTURAL',
  PROGRAMMING_BALANCE = 'PROGRAMMING_BALANCE',
  GRAIN_RECEIPT_QUEUE = 'GRAIN_RECEIPT_QUEUE',

  TRR_REQUESTS_CATTLE = 'TRR_REQUESTS_CATTLE',
  FEED_ORDER_CATTLE = 'FEED_ORDER_CATTLE',
  DRUG_BALANCES_CATTLE = 'DRUG_BALANCES_CATTLE',
  RAW_MATERIAL_PROGRAMMING_CATTLE = 'RAW_MATERIAL_PROGRAMMING_CATTLE',
  SLAUGHTER_REPORT_PORCINE = 'SLAUGHTER_REPORT_PORCINE',
  FEED_ORDER_PORCINE = 'FEED_ORDER_PORCINE',
  TRR_REQUESTS_PORCINE = 'TRR_REQUESTS_PORCINE',
  DRUG_BALANCES_PORCINE = 'DRUG_BALANCES_PORCINE',
  RAW_MATERIAL_PROGRAMMING_PORCINE = 'RAW_MATERIAL_PROGRAMMING_PORCINE',
  SLAUGHTER_REPORT = 'SLAUGHTER_REPORT',
  PORCINE_DOCUMENTS = 'PORCINE_DOCUMENTS',
  PIG_DELIVERY_REPORT = 'PIG_DELIVERY_REPORT',
  ALEGRA = 'ALEGRA',

  BULLS_CATALOG = 'BULLS_CATALOG',
  DRUG_BALANCES = 'DRUG_BALANCES',
  FEED_ORDER = 'FEED_ORDER',
  INDICATORS = 'INDICATORS',
  MILK_MAP = 'MILK_MAP',
  MILK_COLLECTIONS = 'MILK_COLLECTIONS',
  MILK_ANALYSIS = 'MILK_ANALYSIS',
  MILK_ADVANCED_PAYMENT = 'MILK_ADVANCED_PAYMENT',
  MILK_DOCUMENTS = 'MILK_DOCUMENTS',
  RAW_MATERIAL_PROGRAMMING = 'RAW_MATERIAL_PROGRAMMING',
  SQUAD_MANAGEMENT = 'SQUAD_MANAGEMENT',
  NEW_RATION_ORDER_CATTLE = 'NEW_RATION_ORDER_CATTLE',
  NEW_RATION_ORDER_PORCINE = 'NEW_RATION_ORDER_PORCINE',
  PIG_FARM = 'PIG_FARM',
  FARM = 'FARM',
  FINANCING = 'FINANCING',
  LIVESTOCK_CATTLE = 'LIVESTOCK_CATTLE',

  ENVIRONMENTAL_LICENSE = 'ENVIRONMENTAL_LICENSE',
  ENVIRONMENTAL_DOCUMENTS = 'ENVIRONMENTAL_DOCUMENTS',
}

export default CardNames;
